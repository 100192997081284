import { t } from '@lingui/macro'

import { GAMMA_PROPER_NOUN } from 'modules/i18n/properNouns'

// Note: this doc is localized, so don't just paste in new content from the tester doc without adding t tags
// https://staging.gamma.app/docs/Tester-Deck-Mel-V1-July-2023-jovrtc2d4gmq3z3?mode=doc
export const getThemeTesterDoc = () => ({
  type: 'doc',
  content: [
    {
      type: 'document',
      attrs: {
        docId: 'jovrtc2d4gmq3z3',
        background: {
          type: 'none',
        },
        docFlags: {
          cardLayoutsEnabled: true,
        },
        format: null,
        customCode: {},
        settings: {},
      },
      content: [
        {
          type: 'card',
          attrs: {
            id: '41gyf1paszqblkd',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'right',
            layoutTemplateColumns: '75% 25%',
          },
          content: [
            {
              type: 'cardAccentLayoutItem',
              attrs: {
                itemId: 'accent',
                background: {
                  type: 'image',
                  image: {
                    src: 'https://cdn.gamma.app/theme_images/gamma/gamma-1_6b981df6.jpg',
                    meta: {
                      width: 4096,
                      height: 4096,
                    },
                    name: 'gamma-1_6b981df6.jpg',
                    source: 'image.custom',
                  },
                  source: 'accent.theme',
                  accentId: '6b981df6',
                  originalSource: 'image.custom',
                },
              },
            },
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: 'sm',
                  },
                  content: [
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'bold',
                        },
                      ],
                      text: t`Hello`,
                    },
                    {
                      type: 'text',
                      text: ' ',
                    },
                    {
                      type: 'emoji',
                      attrs: {
                        id: 'wave',
                        native: '👋',
                      },
                    },
                  ],
                },
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`This is a theme preview`,
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Here's an example of body text. You can change its font and the color.`,
                    },
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'link',
                          attrs: {
                            href: 'http://gamma.app',
                            source: 'embed.custom',
                            thumbnail: {
                              src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/e057b87f00794790af3243d7b76ae9b3/original/og-image-d666e6.jpg',
                              height: 1200,
                              width: 2400,
                            },
                            embed: null,
                            meta: {
                              description:
                                'A new medium for presenting ideas, powered by AI. Create beautiful, engaging content with none of the formatting and design work.',
                              title: 'Gamma App',
                              medium: 'link',
                              canonical: 'https://gamma.app/',
                              site: 'MeetGamma',
                              icon: 'https://assets.website-files.com/60de2701a7b28f308f619d3d/6107f8275398b158684f03d8_256%20Gamma.png',
                            },
                          },
                        },
                      ],
                      text: t`Your accent color will be used for links.`,
                    },
                    {
                      type: 'text',
                      text: ` `,
                    },
                    {
                      type: 'text',
                      text: t`It will also be used for layouts and buttons. `,
                    },
                  ],
                },
                {
                  type: 'smartLayout',
                  attrs: {
                    variantKey: 'textBoxes',
                    options: {
                      cellSize: 10,
                    },
                    fullWidthBlock: false,
                  },
                  content: [
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: 'default',
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`This is a smart layout: it acts as a text box.`,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: 'default',
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`You can get these by typing /smart`,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Here are your buttons:`,
                    },
                  ],
                },
                {
                  type: 'buttonGroup',
                  attrs: {
                    horizontalAlign: null,
                  },
                  content: [
                    {
                      type: 'button',
                      attrs: {
                        href: 'http://gamma.app',
                        variant: 'solid',
                      },
                      content: [
                        {
                          type: 'text',
                          text: t`Primary button`,
                        },
                      ],
                    },
                    {
                      type: 'button',
                      attrs: {
                        href: 'http://gamma.app',
                        variant: 'outline',
                      },
                      content: [
                        {
                          type: 'text',
                          text: t`Secondary button`,
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`To the right, this is what we call an accent image. We have a set of them with our default themes, but you can change them!`,
                    },
                    {
                      type: 'text',
                      text: '  ',
                    },
                    {
                      type: 'emoji',
                      attrs: {
                        id: 'arrow_right',
                        native: '➡️',
                      },
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Let's get started customizing your theme!`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: '42dtvj8msluivml',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'blank',
            layoutTemplateColumns: null,
          },
          content: [
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Theme background`,
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`This could be an image, color or gradient that will appear behind all the cards on your page.`,
                    },
                  ],
                },
                {
                  type: 'image',
                  attrs: {
                    id: '7zRJt',
                    horizontalAlign: null,
                    src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/88848223e9b04605a381e4db6411f7a6/original/Theme-bg.png',
                    tempUrl: null,
                    uploadStatus: 0,
                    meta: {
                      width: 2172,
                      height: 890,
                      date_file_created: '2023/07/31 19:55:13 GMT',
                      date_file_modified: '2023/07/31 19:58:55 GMT',
                      aspect_ratio: 2.44,
                      has_clipping_path: false,
                      frame_count: 1,
                      colorspace: 'sRGB',
                      has_transparency: false,
                      average_color: '#7d8388',
                    },
                    providerMeta: null,
                    name: 'Theme bg.png',
                    query: null,
                    source: 'image.custom',
                    showPlaceholder: false,
                    fullWidthBlock: false,
                    resize: {
                      clipType: null,
                      clipPath: null,
                      clipAspectRatio: null,
                      width: null,
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: '7o1dzqq2o8he25e',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'blank',
            layoutTemplateColumns: null,
          },
          content: [
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Card Styles`,
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`This controls the appearance of your card, and also the default color of your card.`,
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`The appearance options you have are:`,
                    },
                  ],
                },
                {
                  type: 'smartLayout',
                  attrs: {
                    variantKey: 'imagesText',
                    options: {
                      cellSize: 20,
                      imageShape: 'landscape',
                      imageSize: 0.8,
                      horizontalAlign: 'left',
                    },
                    fullWidthBlock: false,
                  },
                  content: [
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: {
                          source: 'image.custom',
                          uploadStatus: 0,
                          showPlaceholder: false,
                          tempUrl:
                            'blob:https://staging.gamma.app/b6f8c94d-eaf8-4daf-964c-94b3b2f89eaa',
                          src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/8d771488be94454c85e871c332472ee6/original/default.png',
                          meta: {
                            width: 1332,
                            height: 733,
                            date_file_modified: '2023/07/31 20:43:45 GMT',
                            aspect_ratio: 1.817,
                            has_clipping_path: false,
                            frame_count: 1,
                            colorspace: 'sRGB',
                            has_transparency: false,
                            average_color: '#f4eff7',
                          },
                          name: 'default.png',
                          uploadResultStep: 'optimized',
                        },
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Default`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Full opacity, slightly rounded and subtle shadow`,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: {
                          source: 'image.custom',
                          uploadStatus: 0,
                          showPlaceholder: false,
                          tempUrl:
                            'blob:https://staging.gamma.app/409027d2-0fa7-456f-ac62-1898dbd3409f',
                          src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/e9452b5862574277aefb37aa56af0da9/original/glass.png',
                          meta: {
                            width: 1332,
                            height: 733,
                            date_file_modified: '2023/07/31 20:43:48 GMT',
                            aspect_ratio: 1.817,
                            has_clipping_path: false,
                            frame_count: 1,
                            colorspace: 'sRGB',
                            has_transparency: false,
                            average_color: '#c6cbea',
                          },
                          name: 'glass.png',
                          uploadResultStep: 'optimized',
                        },
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Glass`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`The cards have a transparent effect so you could see through the background`,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: {
                          source: 'image.custom',
                          uploadStatus: 0,
                          showPlaceholder: false,
                          tempUrl:
                            'blob:https://staging.gamma.app/ba558b56-c2bc-414b-8106-d9af9c9c3333',
                          src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/914d56fd23744546979d448193fb86ea/original/soft-3d.png',
                          meta: {
                            width: 1332,
                            height: 733,
                            date_file_modified: '2023/07/31 20:43:58 GMT',
                            aspect_ratio: 1.817,
                            has_clipping_path: false,
                            frame_count: 1,
                            colorspace: 'sRGB',
                            has_transparency: false,
                            average_color: '#ebe8f8',
                          },
                          name: 'soft 3d.png',
                          uploadResultStep: 'optimized',
                        },
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Soft 3D`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Rounded edges and soft 3D effect. Works best with same color background`,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: {
                          source: 'image.custom',
                          uploadStatus: 0,
                          showPlaceholder: false,
                          tempUrl:
                            'blob:https://staging.gamma.app/ad7715eb-8def-4374-bf84-61356039226e',
                          src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/abe6bdb73f7549f2a297bbe896f21ffa/original/Finesse.png',
                          meta: {
                            width: 1332,
                            height: 733,
                            date_file_modified: '2023/07/31 20:44:08 GMT',
                            aspect_ratio: 1.817,
                            has_clipping_path: false,
                            frame_count: 1,
                            colorspace: 'sRGB',
                            has_transparency: false,
                            average_color: '#f3f0f6',
                          },
                          name: 'Finesse.png',
                          uploadResultStep: 'optimized',
                        },
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Finesse`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Minimalist, flat cards with a warm tone`,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: {
                          source: 'image.custom',
                          uploadStatus: 0,
                          showPlaceholder: false,
                          tempUrl:
                            'blob:https://staging.gamma.app/520e3bea-5cbf-4a59-ad72-b4f7b704002f',
                          src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/c4db26f534f046b7acad9e82af4dced9/original/daydream.png',
                          meta: {
                            width: 1332,
                            height: 733,
                            date_file_modified: '2023/07/31 20:44:11 GMT',
                            aspect_ratio: 1.817,
                            has_clipping_path: false,
                            frame_count: 1,
                            colorspace: 'sRGB',
                            has_transparency: false,
                            average_color: '#cad1e8',
                          },
                          name: 'daydream.png',
                          uploadResultStep: 'optimized',
                        },
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Daydream`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Rounded edges, high transparency, heavy strokes`,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: {
                          source: 'image.custom',
                          uploadStatus: 0,
                          showPlaceholder: false,
                          tempUrl:
                            'blob:https://staging.gamma.app/056149d4-65e8-47fd-b1de-2f12cf0fbede',
                          src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/70918f7199fd431f86bfe73439d330aa/original/icebreaker.png',
                          meta: {
                            width: 1332,
                            height: 733,
                            date_file_modified: '2023/07/31 20:44:15 GMT',
                            aspect_ratio: 1.817,
                            has_clipping_path: false,
                            frame_count: 1,
                            colorspace: 'sRGB',
                            has_transparency: false,
                            average_color: '#f2f1f6',
                          },
                          name: 'icebreaker.png',
                          uploadResultStep: 'optimized',
                        },
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Icebreaker`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Minimalist, flat cards with a cool tone`,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: 'fmbv2h7t7ezp73a',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'right',
            layoutTemplateColumns: '75% 25%',
          },
          content: [
            {
              type: 'cardAccentLayoutItem',
              attrs: {
                itemId: 'accent',
                background: {
                  type: 'image',
                  image: {
                    src: 'https://cdn.gamma.app/theme_images/gamma/gamma-2_72f520f3.jpg',
                    meta: {
                      width: 4096,
                      height: 4096,
                    },
                    name: 'gamma-2_72f520f3.jpg',
                    source: 'image.custom',
                  },
                  source: 'accent.theme',
                  accentId: '72f520f3',
                  originalSource: 'image.custom',
                },
              },
            },
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Primary accent color`,
                    },
                  ],
                },
                {
                  type: 'bullet',
                  attrs: {
                    indent: 0,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`This could be 1 solid color or, a gradient`,
                    },
                  ],
                },
                {
                  type: 'bullet',
                  attrs: {
                    indent: 0,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`It will apply to buttons, block quotes, smart layouts, callout boxes, `,
                    },
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'link',
                          attrs: {
                            href: 'http://gamma.app',
                            source: 'embed.custom',
                            thumbnail: {
                              src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/40812795ce424e80bff41b2315a3948c/original/og-image-d666e6.jpg',
                              height: 1200,
                              width: 2400,
                            },
                            embed: null,
                            meta: {
                              description:
                                'A new medium for presenting ideas, powered by AI. Create beautiful, engaging content with none of the formatting and design work.',
                              title: 'Gamma App',
                              medium: 'link',
                              xframe: 'sameorigin',
                              csp: "frame-ancestors 'self'",
                              canonical: 'https://gamma.app/',
                              site: 'MeetGamma',
                              icon: 'https://assets.website-files.com/60de2701a7b28f308f619d3d/6107f8275398b158684f03d8_256%20Gamma.png',
                            },
                          },
                        },
                      ],
                      text: t`hyperlinks`,
                    },
                    {
                      type: 'text',
                      text: ' and ',
                    },
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'footnoteLabel',
                          attrs: {
                            noteId: '3lzJQ',
                          },
                        },
                      ],
                      text: t`footnotes`,
                    },
                    {
                      type: 'footnote',
                      attrs: {
                        noteId: '3lzJQ',
                      },
                      content: [
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Hi, I'm a footnote. `,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'text',
                      text: '.',
                    },
                  ],
                },
                {
                  type: 'calloutBox',
                  attrs: {
                    variant: 'note',
                    icon: true,
                    color: null,
                  },
                  content: [
                    {
                      type: 'paragraph',
                      attrs: {
                        horizontalAlign: null,
                        fontSize: null,
                      },
                      content: [
                        {
                          type: 'text',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          text: t`Attention all`,
                        },
                      ],
                    },
                    {
                      type: 'paragraph',
                      attrs: {
                        horizontalAlign: null,
                        fontSize: null,
                      },
                      content: [
                        {
                          type: 'text',
                          text: t`This is a callout box. Its default color will be your primary accent color, but don't worry, you can change it.`,
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'gridLayout',
                  attrs: {
                    colWidths: [38, 62],
                    fullWidthBlock: false,
                  },
                  content: [
                    {
                      type: 'gridCell',
                      attrs: {
                        verticalAlign: null,
                      },
                      content: [
                        {
                          type: 'buttonGroup',
                          attrs: {
                            horizontalAlign: null,
                          },
                          content: [
                            {
                              type: 'button',
                              attrs: {
                                href: 'http://google.com',
                                variant: 'solid',
                              },
                              content: [
                                {
                                  type: 'text',
                                  text: t`Primary button`,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'buttonGroup',
                          attrs: {
                            horizontalAlign: null,
                          },
                          content: [
                            {
                              type: 'button',
                              attrs: {
                                href: '',
                                variant: 'outline',
                              },
                              content: [
                                {
                                  type: 'text',
                                  text: t`Secondary button`,
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'gridCell',
                      attrs: {
                        verticalAlign: null,
                      },
                      content: [
                        {
                          type: 'blockquote',
                          content: [
                            {
                              type: 'paragraph',
                              attrs: {
                                horizontalAlign: null,
                                fontSize: null,
                              },
                              content: [
                                {
                                  type: 'text',
                                  text: t`Example of a block quote, here you can write a quote and we will style it.`,
                                },
                                {
                                  type: 'hardBreak',
                                },
                                {
                                  type: 'hardBreak',
                                },
                                {
                                  type: 'text',
                                  text: '— ',
                                },
                                {
                                  type: 'text',
                                  text: t`Author`,
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: '8b1velkt2tgifw0',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'right',
            layoutTemplateColumns: '75% 25%',
          },
          content: [
            {
              type: 'cardAccentLayoutItem',
              attrs: {
                itemId: 'accent',
                background: {
                  type: 'image',
                  image: {
                    src: 'https://cdn.gamma.app/theme_images/gamma/gamma-3_dde9617f.jpg',
                    meta: {
                      width: 4096,
                      height: 4096,
                    },
                    name: 'gamma-3_dde9617f.jpg',
                    source: 'image.custom',
                  },
                  source: 'accent.theme',
                  accentId: 'dde9617f',
                  originalSource: 'image.custom',
                },
              },
            },
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Secondary accent colors`,
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`In addition to your primary color, secondary accent color will show up as extras in your theme's color palette. You can use them for card backgrounds, smart layouts and text styling.`,
                    },
                  ],
                },
                {
                  type: 'image',
                  attrs: {
                    id: 'Fsocf',
                    horizontalAlign: null,
                    src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/83d1fea614654077b5ca14a2682f450c/original/Accent-colors.png',
                    tempUrl: null,
                    uploadStatus: 0,
                    meta: {
                      width: 569,
                      height: 407,
                      date_file_modified: '2023/07/31 20:07:14 GMT',
                      aspect_ratio: 1.398,
                      has_clipping_path: false,
                      frame_count: 1,
                      colorspace: 'sRGB',
                      has_transparency: true,
                      average_color: '#edeaed',
                    },
                    providerMeta: null,
                    name: 'Accent colors.png',
                    query: null,
                    source: 'image.custom',
                    showPlaceholder: false,
                    fullWidthBlock: false,
                    resize: {
                      clipType: 'inset',
                      clipPath: [
                        '2.681191061675055%',
                        '1.1966543730242307%',
                        '3.830272945250083%',
                        '1.610247629083246%',
                      ],
                      clipAspectRatio: 1.4534992807485934,
                      width: 369.77777777777777,
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: 'brpozwh76abqixf',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'blank',
            layoutTemplateColumns: null,
          },
          content: [
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Here are some smart layout examples`,
                    },
                  ],
                },
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 3,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Timeline',
                    },
                  ],
                },
                {
                  type: 'smartLayout',
                  attrs: {
                    variantKey: 'timeline',
                    options: {
                      numbered: true,
                      hasLine: false,
                      twoSided: false,
                      orientation: 'horizontal',
                    },
                    fullWidthBlock: false,
                  },
                  content: [
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`First title`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`This is the first point of a timeline`,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Second title`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`You can easily add and remove points and we'll auto-resize your content`,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Third title`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`This is why we call them "smart layouts" `,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 3,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Text boxes`,
                    },
                  ],
                },
                {
                  type: 'smartLayout',
                  attrs: {
                    variantKey: 'textBoxes',
                    options: {},
                    fullWidthBlock: false,
                  },
                  content: [
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`First title `,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`This is a text box smart layout`,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Second title`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`You can add/remove as many as you want`,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Third title`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`They will adapt to your screen and are responsive`,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: '8ghft5ydx25i5p9',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'top',
            layoutTemplateColumns: null,
          },
          content: [
            {
              type: 'cardAccentLayoutItem',
              attrs: {
                itemId: 'accent',
                background: {
                  type: 'image',
                  image: {
                    src: 'https://cdn.gamma.app/theme_images/gamma/gamma-4_d43e455b.jpg',
                    meta: {
                      width: 4096,
                      height: 4096,
                    },
                    name: 'gamma-4_d43e455b.jpg',
                    source: 'image.custom',
                  },
                  source: 'accent.theme',
                  accentId: 'd43e455b',
                  originalSource: 'image.custom',
                },
              },
            },
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Accent images`,
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Think of this as your theme library. Our default themes come with a set of accent images to fill space/beautify a wordy slide. This image on the top is an accent image`,
                    },
                    {
                      type: 'text',
                      text: ' ',
                    },
                    {
                      type: 'emoji',
                      attrs: {
                        id: 'arrow_up',
                        native: '⬆️',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: 's8wvu2iezepqvuj',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'blank',
            layoutTemplateColumns: null,
          },
          content: [
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Fonts`,
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Gamma comes with pre-defined sizes of typography that work best for legibility. Feel free to choose font families for your headings and body font.`,
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`You can choose a solid default color for your text. For headings, you can also choose a gradient color.`,
                    },
                  ],
                },
                {
                  type: 'gridLayout',
                  attrs: {
                    colWidths: [50, 50],
                    fullWidthBlock: false,
                  },
                  content: [
                    {
                      type: 'gridCell',
                      attrs: {
                        verticalAlign: null,
                      },
                      content: [
                        {
                          type: 'title',
                          attrs: {
                            horizontalAlign: null,
                            level: 1,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Title font`,
                            },
                          ],
                        },
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 1,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Heading 1`,
                            },
                          ],
                        },
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 2,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Heading 2`,
                            },
                          ],
                        },
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Heading 3`,
                            },
                          ],
                        },
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 4,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Heading 4`,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'gridCell',
                      attrs: {
                        verticalAlign: null,
                      },
                      content: [
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: 'lg',
                          },
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: 'lg',
                          },
                          content: [
                            {
                              type: 'hardBreak',
                            },
                            {
                              type: 'text',
                              text: t`Large body text`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Normal body text`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: 'sm',
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Small body text`,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: '18erntr03ayr2yv',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'blank',
            layoutTemplateColumns: null,
          },
          content: [
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Logo`,
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Upload your logo and we will place it automatically in the best location.`,
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`In ${GAMMA_PROPER_NOUN}, we have two modes. "Doc mode" and "Present mode".`,
                    },
                  ],
                },
                {
                  type: 'gridLayout',
                  attrs: {
                    colWidths: [50, 50],
                    fullWidthBlock: false,
                  },
                  content: [
                    {
                      type: 'gridCell',
                      attrs: {
                        verticalAlign: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Doc mode`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`The logo appears in the bottom left`,
                            },
                          ],
                        },
                        {
                          type: 'image',
                          attrs: {
                            id: '5uAiy',
                            horizontalAlign: null,
                            src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/083d65cf26434a3bb347c82b7d7cbaee/original/Doc-mode.png',
                            tempUrl: null,
                            uploadStatus: 0,
                            meta: {
                              width: 1707,
                              height: 864,
                              date_file_modified: '2023/07/31 19:59:57 GMT',
                              aspect_ratio: 1.976,
                              has_clipping_path: false,
                              frame_count: 1,
                              colorspace: 'sRGB',
                              has_transparency: true,
                              average_color: '#efedee',
                            },
                            providerMeta: null,
                            name: 'Doc mode.png',
                            query: null,
                            source: 'image.custom',
                            showPlaceholder: false,
                            fullWidthBlock: false,
                            resize: {
                              clipType: null,
                              clipPath: null,
                              clipAspectRatio: null,
                              width: null,
                            },
                          },
                        },
                      ],
                    },
                    {
                      type: 'gridCell',
                      attrs: {
                        verticalAlign: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`Present mode`,
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: t`The logo will appear in the top left`,
                            },
                          ],
                        },
                        {
                          type: 'image',
                          attrs: {
                            id: 'Hiipm',
                            horizontalAlign: null,
                            src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/423a51f2f4b143358a5a83f65a6440c5/original/Present-mode.png',
                            tempUrl: null,
                            uploadStatus: 0,
                            meta: {
                              width: 1572,
                              height: 865,
                              date_file_modified: '2023/07/31 20:00:01 GMT',
                              aspect_ratio: 1.817,
                              has_clipping_path: false,
                              frame_count: 1,
                              colorspace: 'sRGB',
                              has_transparency: false,
                              average_color: '#f8f8f9',
                            },
                            providerMeta: null,
                            name: 'Present mode.png',
                            query: null,
                            source: 'image.custom',
                            showPlaceholder: false,
                            fullWidthBlock: false,
                            resize: {
                              clipType: null,
                              clipPath: null,
                              clipAspectRatio: null,
                              width: 332.44444444444446,
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: 'iq6zoihwsu1qjyr',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'left',
            layoutTemplateColumns: null,
          },
          content: [
            {
              type: 'cardAccentLayoutItem',
              attrs: {
                itemId: 'accent',
                background: {
                  type: 'image',
                  image: {
                    src: 'https://cdn.gamma.app/theme_images/gamma/gamma-5_dd94f277.jpg',
                    meta: {
                      width: 4096,
                      height: 4096,
                    },
                    name: 'gamma-5_dd94f277.jpg',
                    source: 'image.custom',
                  },
                  source: 'accent.theme',
                  accentId: 'dd94f277',
                  originalSource: 'image.custom',
                },
              },
            },
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`That's it!`,
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: t`Thank you, and we hope you enjoy your Gamma experience`,
                    },
                    {
                      type: 'text',
                      text: ' ',
                    },
                    {
                      type: 'emoji',
                      attrs: {
                        id: 'purple_heart',
                        native: '💜',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
})
